import onmount from 'onmount'

onmount('[data-component=tutorials-section]', function () {
  const triggers = $(this).find('[data-target]')

  triggers.on('click', function () {
    triggers.removeClass('selected')
    triggers.each(function () {
      $(this.dataset.target).hide()
    })

    var selector = this.dataset.target
    $(selector).show()
    $(this).addClass('selected')
  })
})
