import './remodal'
import 'remodal/dist/remodal.css'
import 'remodal/dist/remodal-default-theme.css'

$(function () {
  //user-agent//
  var judgeViewPort = function () {
    var ua = navigator.userAgent
    // viewportのDOM要素 取得
    var viewportElement = document.querySelector("meta[name='viewport']")
    // viewport cotennt用の変数
    var viewportContent
    if (
      ua.indexOf('iPhone') > 0 ||
      ua.indexOf('iPod') > 0 ||
      (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)
    ) {
      // スマホのとき
      if (Math.abs(window.orientation) === 90) {
        // スマホ 横向きのとき
        viewportContent = 'width=1200px'
      } else {
        // スマホ 縦向きのとき
        viewportContent = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'
      }
    } else {
      // PC・タブレットのとき
      viewportContent = 'width=1200px'
    }
    viewportElement.setAttribute('content', viewportContent)
  }

  judgeViewPort()

  $(window).on('orientationchange resize', function () {
    // リサイズ時 縦向きと横向きのviewportの変換を行う
    judgeViewPort()
  })

  if (window.innerWidth > 480) {
    var submitIcon = $('.searchbox-icon')
    var inputBox = $('.searchbox-input')
    var searchBox = $('.searchbox')
    var isOpen = false
    submitIcon.click(function () {
      if (isOpen == false) {
        searchBox.addClass('searchbox-open')
        inputBox.focus()
        isOpen = true
      } else {
        searchBox.removeClass('searchbox-open')
        inputBox.focusout()
        isOpen = false
      }
    })
    submitIcon.mouseup(function () {
      return false
    })
    searchBox.mouseup(function () {
      return false
    })

    $(document).mouseup(function () {
      if (isOpen == true) {
        $('.searchbox-icon').css('display', 'block')
        submitIcon.click()
      }
    })

    function buttonUp() {
      var inputVal = $('.searchbox-input').val()
      inputVal = $.trim(inputVal).length
      if (inputVal !== 0) {
        $('.searchbox-icon').css('display', 'none')
      } else {
        $('.searchbox-input').val('')
        $('.searchbox-icon').css('display', 'block')
      }
    }
  }
  //sp-dropdown
  $('#sp-dropdown').bind('touchstart', function () {
    var spanParent = document.getElementById('sp-dropdown')
    var span1 = spanParent.children[0]
    var span2 = spanParent.children[1]
    var span3 = spanParent.children[2]
    if ($('#mobile-nav').hasClass('nav-close')) {
      span1.style.transform = 'translateY(6px) rotate(-45deg)'
      span1.style.transitionDuration = '300ms'
      span1.style.transitionTimingFunction = 'cubic-bezier(0.31, 0.19, 0.16, 10ms)'
      span2.style.opacity = '0'
      span2.style.transitionDuration = '100ms'
      span2.style.transitionTimingFunction = 'cubic-bezier(0.31, 0.19, 0.16, 10ms)'
      span3.style.transform = 'translateY(-6px) rotate(45deg)'
      span3.style.transitionDuration = '300ms'
      span3.style.transitionTimingFunction = 'cubic-bezier(0.31, 0.19, 0.16, 10ms)'
    } else {
      span1.style.transform = 'translateY(0) rotate(0deg)'
      span1.style.transitionDuration = '300ms'
      span1.style.transitionTimingFunction = 'cubic-bezier(0.31, 0.19, 0.16, 10ms)'
      span2.style.opacity = '1'
      span2.style.transitionDuration = '300ms'
      span2.style.transitionTimingFunction = 'cubic-bezier(0.31, 0.19, 0.16, 10ms)'
      span3.style.transform = 'translateY(0) rotate(0deg)'
      span3.style.transitionDuration = '300ms'
      span3.style.transitionTimingFunction = 'cubic-bezier(0.31, 0.19, 0.16, 10ms)'
    }
    $('#mobile-nav').toggleClass('nav-close')
  })
  $('#mobile-nav .site-nav_has-children').click(function () {
    // $('#mobile-nav .site-nav_has-children').bind('touchstart', function() {
    if ($(this).next().hasClass('nav-close')) {
      $(this).css({
        boxShadow: 'inset 0px 3px 9px -6px'
      })
      $(this).prev().css({
        display: 'block'
      })
      $(this).children().css({
        transform: 'rotateX(-180deg)',
        transitionDuration: '300ms',
        transitionTimingFunction: 'cubic-bezier(0.31, 0.19, 0.16, 10ms)'
      })
    } else {
      $(this).css({
        boxShadow: ''
      })
      $(this).prev().css({
        display: 'none'
      })
      $(this).children().css({
        transform: 'rotateX(0deg)',
        transitionDuration: '300ms',
        transitionTimingFunction: 'cubic-bezier(0.31, 0.19, 0.16, 10ms)'
      })
    }
    $(this).next().toggleClass('nav-close')
  })

  //search header//
  $('.steps__button').on('click', function () {
    $(this).children('.close').toggle()
    $(this).children('.open').toggle()
  })
  var input = $(this).find('input[name="q"]')

  $('.icon-search').on('click', function () {
    // $('.icon-search').bind('touchstart', function() {
    if ($('.searchbox-icon').hasClass('search-close')) {
      $('.search-img').css({
        display: 'none'
      })
      $('.searchbox-icon').css({
        display: 'block'
      })
      $('.searchbox').removeClass('searchbox-open')
    } else {
      $('.search-img').css({
        display: 'block'
      })
      $('.searchbox-icon').css({
        display: 'none'
      })
      $('.searchbox').addClass('searchbox-open')
    }
    $('.searchbox-icon').toggleClass('search-close')
  })
  //site__nav__search//
  $('.site__nav__search__input').focus(function () {
    $(this).animate(
      {
        width: '900px'
      },
      {
        duration: 'normal',
        easing: 'swing'
      }
    )
    $(this).css({
      border: '1px solid #e5e5e5'
    })
  })
  $('.site__nav__search__input').focusout(function () {
    $(this).animate(
      {
        width: '0px'
      },
      {
        duration: 'normal',
        easing: 'swing'
      }
    )
  })
  //site__nav__search-end//

  //to-top//
  $('#to-top').click(function () {
    $('body,html').animate(
      {
        scrollTop: 0
      },
      500
    )
    return false
  })
  //to-top-end//

  $('.search-button').click(function () {
    $('.search-bar').toggleClass('active')
  })
  //modal-function//
  console.log($('.grid--sample-card-section').find('a'))
  $('.grid--sample-card-section')
    .find('a')
    .on('click touch', function () {
      $(`a[data-remodal-id="${$(this).attr('href')}"]`).remodal()
    })
  //modal-function-end//

  //reset.password//
  if ($('a[href="#recover"]').length > 0) {
    $('a#RecoverPassword').bind('click touch', function () {
      $('#RecoverPasswordForm').slideToggle()
    })
  }
  $('.search-button').click(function () {
    $('.search-bar').toggleClass('active')
  })
})
