import onmount from 'onmount'
import intlTelInput from 'intl-tel-input'

// https://intl-tel-input.com/node_modules/intl-tel-input/examples/gen/is-valid-number.html
onmount('#mobile-number', async function () {
  var input = document.querySelector('#mobile-number'),
    errorMsg = document.querySelector('#error-msg'),
    validMsg = document.querySelector('#valid-msg'),
    phoneInput = document.querySelector('input[name=phone]')

  // Load the (large) utils script asynchronously to avoid blocking the page load
  await import('intl-tel-input/build/js/utils')

  // here, the index maps to the error code returned from getValidationError - see readme
  var errorMap = [
    'Invalid number',
    'Invalid country code',
    'Too short',
    'Too long',
    'Invalid number'
  ]

  // initialise plugin
  var iti = intlTelInput(input)

  var reset = function () {
    input.classList.remove('error')
    errorMsg.innerHTML = ''
    errorMsg.classList.add('hide')
    validMsg.classList.add('hide')
  }

  // on blur: validate
  input.addEventListener('blur', function () {
    reset()
    if (input.value.trim()) {
      if (iti.isValidNumber()) {
        validMsg.classList.remove('hide')
      } else {
        input.classList.add('error')
        var errorCode = iti.getValidationError()
        errorMsg.innerHTML = errorMap[errorCode]
        errorMsg.classList.remove('hide')
      }
    }

    phoneInput.value = iti.getNumber()
  })

  // on keyup / change flag: reset
  input.addEventListener('change', reset)
})
