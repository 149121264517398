// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import onmount from 'onmount'

import 'stylesheets/application.scss'
import 'intl-tel-input/build/css/intlTelInput.css'

import 'javascripts/channels'

import 'javascripts/utils/globals'
import 'javascripts/utils/images'

import 'javascripts/legacy/timber'
import 'javascripts/legacy/theme'
import 'javascripts/legacy/defined-common'

import 'javascripts/libphonenumber/utils'
import 'javascripts/behaviors/mobile-number'
import 'javascripts/behaviors/faq-section'
import 'javascripts/behaviors/tutorials-section'

import 'javascripts/components/home-banner-carousel'

onmount()
