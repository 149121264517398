import onmount from 'onmount'

onmount('[data-component="hero-carousel"]', function () {
  $(this).find('.item').removeClass('hide') // to avoid lag

  $(this).slick({
    arrows: false,
    dots: true,
    autoplay: $(this).data('autoplay'),
    autoplaySpeed: $(this).data('autoplaySpeed'),
    adaptiveHeight: true
  })
})
